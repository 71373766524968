:root {
  --color-primary: #FE9102;
  --color-secondary: #FDDE52;
  --font-primary: "Montserrat Alternates", sans-serif;
  --font-secondary: "Roboto", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


*::selection {
  background-color: var(--color-primary);
  color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@mixin header() {
  color: #333333;
  font-family: var(--font-primary);
  font-weight: 700;
  position: relative;
  margin-top: 0;
  text-rendering: optimizelegibility;
  font-size: 3rem;
  letter-spacing: .05em;
  line-height: 4rem;
  margin-bottom: 4rem;
}

@mixin link() {
  font-family: var(--font-secondary);
  color: #ffffff;
  border: 3px solid var(--color-primary);
  padding: 1rem 2.5rem;
  margin-top: 1rem;
  background: var(--color-primary) !important;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  line-height: 1.2;
  outline: none;
  text-transform: capitalize;
  transition: all 0.4s ease;
  border-radius: .5rem;
  cursor: pointer;

  @media only screen and (max-width: 1000px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  &:hover {
    color: var(--color-primary);
    background: #ffffff !important;
    text-decoration: none;
  }
}

@mixin hover() {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(254, 145, 2, .6);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

@mixin title() {
  font-family: var(--font-secondary);
  font-weight: 700;
  position: relative;
  margin-top: 0;
  color: var(--color-primary);
  font-size: 2rem;
  letter-spacing: 1px;
  line-height: 2.8rem;
}

@mixin desc() {
  font-family: var(--font-primary);
  font-size: 17px;
  line-height: 24px;
  color: #777;
  overflow: hidden;
  padding-bottom: 2.4rem;
  letter-spacing: .05em;
}

html {
  font-size: 62.5% !important; // 1rem = 10px, 10px/16px = 62.5%
  scroll-behavior: smooth;

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}

body {
  font-family: var(--font-primary);
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  zoom: 1;
}


.nav {
  background-color: #fff;
  font-family: inherit !important;
  flex-direction: column !important;
  flex-wrap: nowrap;
  overflow: visible !important;

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.toggler-dots {
  font-size: 3rem !important;
  margin-top: .5rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem !important;
  }
}

.nav-logo {
  display: none;

  @media only screen and (max-width: 900px) {
    display: block !important;
  }

  &-img {
    max-width: 160px;
    max-height: 41px;
    width: auto;
    height: auto;

    @media only screen and (max-width: 768px) {
      max-width: 100px;
      max-height: 30px;
    }
  }

  &-login {
    display: none;
    font-family: "Roboto", sans-serif;
    color: #151515;
    font-size: 1.4rem !important;
    cursor: pointer;

    &-icon {
      margin-right: 1rem;
      color: var(--color-primary);
    }

    &:hover {
      color: var(--color-primary);
    }

    @media only screen and (max-width: 900px) {
      display: block !important;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.4rem !important;
    }

    @media only screen and (max-width: 400px) {
      font-size: 1.2rem !important;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between !important;
  align-content: center !important;
  padding-top: 4rem !important;
  font-family: inherit !important;

  @media only screen and (max-width: 900px) {
    display: none;
  }

  &_logo {
    display: flex;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 8rem;

    @media only screen and (max-width: 1000px) {
      margin-right: 5rem;
    }

    &-image {
      float: left;
      margin-right: 1.2rem;
      display: inline !important;

      &--img{
        width: 5.8rem;
        max-width: 100%;
        height: 5.6rem;
      }
    }

    &-content {
      margin-top: 1rem;

      &--name {
        font-family: var(--font-primary);
        font-size: 3rem;
        font-weight: 900 !important;
        color: var(--color-primary);
        line-height: 2.5rem;
        letter-spacing: 2px;
      }

      &--desc {
        font-family: var(--font-secondary);
        font-size: 1.7rem;
        font-weight: bold;
        color: #333333;
        letter-spacing: 2px;
      }
    }

  }

  &_content {
    margin-top: 1rem;
    text-align: right;

    @media only screen and (max-width: 900px) {
      font-size: 2rem !important;
      padding: 1rem !important;
      display: block !important;
    }

    &-list {
      list-style: none;

      @media only screen and (max-width: 900px) {
        margin-left: 2rem !important;
      }
    }

    &-phone {
      color: #333333;
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.7rem;
      font-family: var(--font-primary);
      letter-spacing: 1px;
      cursor: pointer;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
        letter-spacing: .2em;
      }

      &:hover {
        color: var(--color-primary);
      }
    }

    &-desc {
      font-family: var(--font-primary);
      line-height: 2.4rem;
      color: #777777;
      font-size: 1.7rem;
    }
  }
}

.m-r-5{
  margin-right: 3rem !important;

  @media only screen and (max-width: 990px) {
    margin-right: 0 !important;
  }
}

.bullet{
  margin-left: 7rem !important;

  @media only screen and (max-width: 990px) {
    margin-left: 1rem !important;
  }
}

.l-h-1{
  line-height: 1;
}

.bullet li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--color-primary); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 4rem;
}

.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: .2s;
  z-index: 1000 !important;
}



.bottom {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto !important;
  background-color: #fff !important;
  border-radius: .1rem;
  position: relative;
  top: 3.5rem;
  z-index: 10;
  transition: all .3s ease-in-out;

  @media only screen and (max-width: 990px) {
    top: 0;
  }

  // @media only screen and (max-width: 900px) {
  //   position: fixed !important;
  //   margin: 0 !important;
  //   padding: 0 !important;
  //   top: 0 !important;
  //   left: 0 !important;
  //   max-width: 100% !important;
  //   z-index: 100;
  // }


  &-active {
    position: fixed;
    display: flex;
    top: 0 !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    z-index: 100;
    transition: all .3s ease-in-out;

    @media only screen and (max-width: 1200px) {
      padding-left: 20rem !important;
    }

    @media only screen and (max-width: 1000px) {
      padding-left: 10rem !important;
    }

    @media only screen and (max-width: 900px) {
      display: none !important;
    }
  }

  &_content {
    margin: 2rem auto !important;
    font-weight: 900 !important;

    &-list {
      display: inline-block;
      position: relative;
      margin: 0 2rem;

      @media only screen and (max-width: 900px) {
        &:first-child {
          padding-top: 3rem !important;
        }

        margin-left: 1rem !important;
        line-height: 1.7 !important;
      }


      &-btn {
        margin-top: -.8rem !important;
        margin-left: 10rem !important;

        @media only screen and (max-width: 1200px) {}

        @media only screen and (max-width: 980px) {
          margin-left: 0rem !important;
          margin-top: 2rem !important;
        }
      }

      &:not(:first-child):after {
        content: "\f111";
        position: absolute;
        font-family: "Font Awesome 5 Free" !important;
        color: #e1e1e1;
        display: block;
        transform: translate(-5px, -50%);
        font-stretch: normal;
        font-size: 1rem;
        line-height: 12px;
        left: 0;
        top: 50%;

        @media only screen and (max-width: 1200px) {
          font-size: .8rem;
          line-height: 1rem;
          top: 30%;
        }
      }
    }

    &-link {
      font-stretch: normal;
      font-size: 1.8rem;
      line-height: 2rem;
      font-family: var(--font-secondary);
      margin-left: 1rem !important;
      margin-right: 1rem !important;
      position: relative;
      z-index: 1;
      color: #3D3D3D !important;
      background: transparent;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media only screen and (max-width: 990px) {
        margin: .5rem 0;
      }

      &:hover {
        color: var(--color-primary) !important;
      }

      &:active {
        color: var(--color-primary) !important;
      }

      @media only screen and (max-width: 1200px) {
        font-size: 1.6rem;
        line-height: 1rem;
        margin-left: 0rem !important;
        margin-right: 0rem !important;
      }


      &-btn {
        font-family: var(--font-secondary);
        background-color: var(--color-primary);
        color: #ffffff !important;
        padding: 1rem 1.5rem !important;
        font-stretch: normal;
        font-size: 1.8rem;
        line-height: 2rem;
        overflow: hidden;
        display: inline-block;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #ffffff !important;
          color: var(--color-primary) !important;
        }

        &:active {
          background-color: #ffffff !important;
        }
      }
    }
  }
}

.active {
  color: var(--color-primary) !important;
  background: #ffffff !important;
}

.navbar li.active a {
  color: var(--color-primary) !important;
  background: #ffffff !important;
}

.navbar-nav {
  background-color: #fff !important;
  transition: all .3s ease-in-out;

  @media only screen and (max-width: 900px) {
    background-color: #fff !important;
  }
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler-icon {
  width: 4rem !important;
  height: 4rem !important;

  @media only screen and (max-width: 768px) {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.nav-2 {
  display: none !important;
  transition: all .3s ease-in-out;

  @media only screen and (max-width: 900px) {
    display: block !important;
  }
}

.navbar{

  @media only screen and (max-width: 990px) {
    width: 100% !important;
  }
}


.carousel_content {

  &-1 {
    background-image: url("/assets/img/slider/1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &-2 {
    background-image: url("/assets/img/slider/2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &-3 {
    background-image: url("/assets/img/slider/3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }


  &-para {
    text-align: center;
    color: #ffffff;
    font-size: 4.5rem;
    line-height: 5rem;
    letter-spacing: 2px;
    font-family: var(--font-secondary);
    font-weight: bold;
    padding: 35vh 0;
    text-transform: uppercase;
    text-rendering: optimizelegibility;
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 768px) {
      font-size: 3rem;
      line-height: 3rem;
      font-weight: bold;
      padding: 25vh 0;
    }

    @media only screen and (max-width: 480px) {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: bold;
      padding: 20vh 0;
    }


    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      border-top: .3rem solid #ffffff;
      width: 100%;
      left: 0;

    }

    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      border-top: .3rem solid #ffffff;
      width: 100%;
      left: 0;
    }

    &--p {
      font-family: var(--font-primary);
      padding: 2rem 0;
      font-weight: 900 !important;
      margin: 0 10% !important;

    }

  }
}

.carousel-icon {
  font-size: 3rem !important;
}


.floating-btns {
  margin-top: -7rem;
}

.gotop {
  position: fixed;
  transition: .3s;
  color: #ffffff;
  background: var(--color-primary);
  border: 1px solid #fff;
  text-align: center;
  height: 5rem;
  bottom: 8rem;
  border-radius: 1rem;
  width: 5rem;
  right: 5rem;
  text-decoration: none;
  line-height: 5rem;
  font-size: 2rem;
  z-index: 100;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
    visibility: hidden;
  }

  &:hover {
    color: var(--color-primary);
    background: #ffffff;
  }

}
.cards {
  background: var(--color-primary);
  width: 100%;
  max-width: 100%;
  padding-top: 12rem !important;

  &_row {
    flex-wrap: nowrap !important;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap !important;
    }
  }

  &_card {
    padding: 3rem;
    padding-bottom: 7rem;
    border: 2px solid #ffffff;
    margin: 1rem;
    position: relative;
    text-align: center;
    color: #ffffff;
    transition: all .1s ease-in-out;

    &:hover {
      cursor: pointer;
      border: 4px solid #ffffff;
    }

    &-image {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 32px;
      margin-left: 0px;

      &-img {
        font-size: 0;
        line-height: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }

    &-title {
      font-family: var(--font-secondary);
      margin-bottom: 11px;
      font-size: 30px;
      letter-spacing: 2px;
      line-height: 40px;
      font-weight: bold;
      position: relative;
      margin-top: 0;
      text-rendering: optimizelegibility;
    }

    &-p {
      font-family: 'Raleway', sans-serif;
      font-size: 17px;
      line-height: 24px;
      padding-left: 20%;
      padding-right: 20%;
      padding-bottom: 2rem;
    }

    &-p2 {
      font-family: 'Raleway', sans-serif;
      font-size: 17px;
      line-height: 24px;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: .5rem;
    }

    &-link {
      position: absolute;
      bottom: 2rem;
      left: 0;
      right: 0;
      font-family: var(--font-secondary);
      color: #ffffff;
      border: none;
      padding: 0;
      background: transparent !important;
      text-decoration: underline;
      display: inline-block;
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 1.7;
      border: 1px solid #fff;
      padding: 1rem;
      text-decoration: none;
      outline: none;
      transition: all 0.4s ease;
      width: 16rem;
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
        background-color: #fff !important;
        transition: .2s all ease-in;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.aboutus {
  width: 100%;
  max-width: 100%;
  padding: 7rem 0rem;
  text-align: center;

  &_title {
    @include header();

    @media only screen and (max-width: 990px) {
      margin-top: 2rem;
    }
  }

  &_title2 {
    color: #333333;
    font-family: var(--font-primary);
    font-weight: 900;
    position: relative;
    margin-top: 0;
    text-rendering: optimizelegibility;
    font-size: 3rem;
    letter-spacing: .05em;
    line-height: 4rem;
    margin-bottom: 4rem;
  }

  &_desc {
    font-family: var(--font-secondary);
    font-weight: bold;
    position: relative;
    margin-top: 0;
    text-rendering: optimizelegibility;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 17px;
    color: var(--color-primary);
  }

  &_content {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    line-height: 1.7;
    color: #000;
    padding-bottom: 11px;
    font-weight: 400;
  }

  &-link {
    @include link();
  }

  &-link2 {
    font-family: var(--font-secondary);
    color: var(--color-primary);
    border: 3px solid #fff;
    padding: 1rem 2.5rem;
    margin-top: 1rem;
    background: #fff !important;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    line-height: 1.2;
    outline: none;
    text-transform: capitalize;
    transition: all 0.4s ease;
    border-radius: .5rem;
    cursor: pointer;
  
    @media only screen and (max-width: 1000px) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  
    &:hover {
      color: #fff;
      background: var(--color-primary) !important;
      text-decoration: none;
    }
  }
}

.about_images {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap !important;
  }

  &_content {
    position: relative;
    padding: 14rem 0;

    &-1{
      position: relative;
      background-image: url("../img/meals/1.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-2{
      position: relative;
      background-image: url("../img/meals/2.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-3{
      position: relative;
      background-image: url("../img/meals/3.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-4{
      position: relative;
      background-image: url("../img/aboutus/1.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-5{
      position: relative;
      background-image: url("../img/aboutus/2.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-6{
      position: relative;
      background-image: url("../img/aboutus/3.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 60rem;
      z-index: 0;
    }

    &-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      &-img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &-para {
      position: absolute;
      top: 12%;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      color: white;
      max-width: 70% !important;
      width: 70% !important;
      margin-left: 7rem !important;

      &--p {
        font-weight: bold;
        font-stretch: normal;
        font-size: 30px;
        line-height: 39px;
        font-family: var(--font-primary);
        text-align: center;
        text-transform: uppercase;
        margin: 5rem 0;

        @media only screen and (max-width: 1200px) {
          font-size: 2rem;
          line-height: 2rem;
          margin: 2rem 0;
        }

        @media only screen and (max-width: 768px) {
          font-size: 3rem;
          line-height: 3rem;
          margin: 2rem 0;
        }

        @media only screen and (max-width: 400px) {
          font-size: 2rem;
          line-height: 2rem;
          margin: 1rem 0;
        }

      }

      &--line {
        width: 100%;
        padding: .1rem;
        background: #ffffff;
      }
    }
  }
}

.menu {
  width: 100%;
  max-width: 100%;
  padding: 7rem 0rem;
  text-align: center;

  &_title {
    @include header();
  }

  &_content {

    &-image {
      position: relative;

      &-img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    &-title {
      font-family: var(--font-secondary);
      font-weight: bold;
      position: relative;
      margin-top: 0;
      text-rendering: optimizelegibility;
      color: var(--color-primary);
      font-size: 2rem;
      font-weight: bold !important;
      letter-spacing: 1px;
      line-height: 2.8rem;
      margin: 1.7rem 0rem;
    }

    &-link {
      @include hover();
    }

  }

  &_link {
    @include link();
  }
}

.footer {
  width: 100%;
  max-width: 100%;
  padding-top: 7rem;

  &-head {
    @include header();
    font-size: 2rem !important;
    margin-bottom: 2rem;
  }

  &-links {
    margin: 0;
    padding: 0;
    list-style: none;

    &-li {
      margin-bottom: 1rem !important;
    }

    &-link {
      font-family: 'Raleway', sans-serif;
      color: var(--color-primary);
      font-size: 1.7rem;
      line-height: 24px;
      text-decoration: none;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &_contacts {

    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 11px;

    &-info {
      padding-bottom: 10px;

      &-social-links {
        margin: 2rem 0 2.4rem 0rem;
        padding: 0;
        list-style: none;

        &-li {
          display: inline-block;
          margin: 0 21px 0 0;
        }

        &-link {
          color: #333333;
          display: block;
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;

          &:hover {
            color: var(--color-primary);
            text-decoration: none;
          }
        }
      }
    }
  }

  &-copy {
    font-family: 'Raleway', sans-serif;
    background: var(--color-primary);
    font-size: 17px;
    line-height: 24px;
    color: #777777;
    padding: 2.5rem 0;
  }
}

.breadcrumbs-custom-path {
  margin-top: 7rem;
  letter-spacing: 0;
  list-style: none;
  padding: 0;
  text-align: center;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    margin-top: 10rem !important;
  }

  &-li {
    font-family: var(--font-secondary);
    font-size: 1.6rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.7;
    font-weight: 900;
    color: #3d3d3d;

    &-active {
      color: var(--color-primary) !important;
    }
  }
}

.about {
  width: 100%;
  max-width: 100%;
  padding-top: 3.5rem;
  padding-bottom: 7rem;
  text-align: center;
}

.services {
  width: 100%;
  max-width: 100%;
  padding: 7rem 0;
  text-align: center;

  &_title {
    @include header();
  }

  &_content {
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    line-height: 24px;
    color: #777777;
    padding-bottom: 11px;
  }

  &_cards {
    margin-top: 1rem;
    padding-top: 7rem !important;
  }

  &_card {

    &-image {

      &-img {
        width: 100%;
        height: 100%;
      }

      &-img2 {
        width: 100%;
        max-width: 100%;
        height: 20rem;
        object-fit: cover;
        margin-bottom: 1rem;
      }
    }

    &-title {
      font-family: var(--font-secondary);
      color: var(--color-primary) !important;
      font-size: 1.8rem !important;
      margin-bottom: 1.7rem !important;
      margin-top: 1.7rem !important;
      letter-spacing: 1px !important;
      line-height: 2rem !important;
      font-weight: bold !important;
    }

    &-p {
      font-family: 'Raleway', sans-serif;
      font-size: 17px;
      line-height: 24px;
      color: #000;
      padding-bottom: 11px;
    }
  }
}

.testimonials {
  width: 100%;
  max-width: 100%;
  padding-top: 7rem;
  padding-bottom: 3.5rem;
  text-align: center;

  &_title {
    @include header();
  }

  &_main {

    &-card {

      @media only screen and (max-width: 768px) {
        margin-bottom: 2rem !important;
      }

      &-icon {
        font-family: var(--font-secondary);
        font-weight: bold;
        font-stretch: normal;
        font-size: 4rem;
        line-height: 2.8rem;
        color: var(--color-primary);
      }

      &-content {

        &-desc {
          font-family: 'Raleway', sans-serif;
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: #777777;
          padding-top: 2rem;
          padding-bottom: 1rem;
        }

        &-name {
          font-family: montserrat alternates, sans-serif;
          font-weight: 700;
          position: relative;
          margin-top: 0;
          text-transform: uppercase;
          text-rendering: optimizelegibility;
          color: #fe9102;
          font-size: 2rem;
          letter-spacing: 1px;
          line-height: 2.8rem;
          margin-bottom: 1.7rem;
        }
      }
    }
  }
}



.simple {
  width: 100%;
  max-width: 100%;
  padding-top: 7rem;
  padding-bottom: 3.5rem;

  &_title {
    @include header();
    text-align: center;
  }

  &_main {

    &-card {

      @media only screen and (max-width: 768px) {
        margin-bottom: 3rem !important;
      }

      &-title {
        font-family: var(--font-secondary);
        font-weight: bold;
        font-stretch: normal;
        font-size: 2rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
        color: var(--color-primary);
      }

      &-content {

        &-desc {
          font-family: 'Raleway', sans-serif;
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: #777777;
          padding-top: 2rem;
          padding-bottom: 1rem;
        }

        &-price {
          font-family: var(--font-primary);
          font-size: 17px;
          line-height: 24px;
          color: var(--color-primary);

          &--value {
            font-family: var(--font-primary);
            color: #777;
          }
        }
      }
    }
  }
}

.details {
  width: 100%;
  max-width: 100%;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;

  &_title {
    @include header();
    text-align: center;
  }

  &_main {

    &-card {
      @media only screen and (max-width: 768px) {
        margin-bottom: 3rem !important;
      }

      &-title {
        font-family: var(--font-secondary);
        font-weight: bold;
        font-stretch: normal;
        font-size: 2rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
        color: var(--color-primary);
      }

      &-content {

        &-desc {
          font-family: 'Raleway', sans-serif;
          font-size: 1.7rem;
          line-height: 2.4rem;
          color: #777777;
        }

        &-price {
          font-family: var(--font-primary);
          font-size: 17px;
          line-height: 24px;
          color: var(--color-primary);
          margin-top: 2rem;

          &--value {
            font-family: var(--font-primary);
            color: #777;
          }
        }
      }
    }
  }
}


.contact{
  margin: 0 auto;
  background-color: #fff;

  &_box{
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    width: 100%;
    padding-right: 4rem;

    @media only screen and (max-width: 990px) {
      padding-right: 0;
      box-shadow: none !important;
    }
  }

  &_content{

    &-p{
      color: #2C3439;
      font-family: "Inter", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 30px
    }

    &-line{
      background-color: var(--color-primary);
      width: 100%;
      height: .3rem;
    }

    &-link{
      font-weight: 600 !important;
      color: var(--color-primary) !important;

      &:hover{
        text-decoration: none;
        color: var(--color-secondary) !important;
        transition: .2s all ease-in-out;
        cursor: pointer;
      }
    }
  }

  &_form2{
      width: 100%;

      @media only screen and (max-width: 990px) {
        margin-top: 2rem;
      }

      @media only screen and (max-width: 768px) {
        margin-left: 2rem;
      }
  }

  &_form{
    width: 60%;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    &-label{
      color: #000;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    &-input{
      font-family: 'Inter', serif;
      background: #fff;
      border: 0;
      outline: none;
      border: 1px solid #ccc !important;
      color: #000;
      height: 4.2rem;
      padding: 1.2rem 4.3rem 1.2rem 1.3rem;
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: .5rem;
    }

    &-button{
      color: #fff;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      background-color: var(--color-primary);
      border-radius: .5rem;
      padding: .7rem 1.5rem;
      margin-top: 3rem;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      &:hover {
        text-decoration: none;
        background-color: var(--color-secondary) !important;
        transition: .3s all ease-in-out;
        outline: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  &_image{

    &-img{
      width: 95%;
      max-width: 100%;
      height: 72rem;
      object-fit: cover;

      @media only screen and (max-width: 990px) {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.active-clr{
  font-weight: 700 !important;
}

.hover{
  color: #000 !important;

  &:hover{
    text-decoration: none;
    color: var(--color-primary) !important;
    cursor: pointer;
  }
}


.devis {
  width: 100%;
  max-width: 100%;
  padding-top: 7rem;
  padding-bottom: 3.5rem;

  &_title {
    @include header();
    text-align: center;
  }
}

.link {
  @include link();
}


// Animations

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 0.5s;
  -moz-animation: fadeIn ease-in 0.5s;
  animation: fadeIn ease-in 0.5s;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;

  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;

}

.fade-in.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.fade-in.two {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fade-in.three {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  animation-delay: 1.3s;
}


.slide-up {
  -webkit-animation: slide-up 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}

.slide-up.one {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.slide-up.two {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.slide-up.three {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.bg-prim{
  background-color: var(--color-primary)
}

.m-l-5{
  margin-left: 5rem
}

.stripe{
  background-image: url("../img/stripe.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: -1;
    transition: 0.5s;
  }
}

.font-prim{
  font-family: var(--font-primary) !important;
}

.section {
  padding: 7rem 27rem;
  overflow-x: hidden !important;

  @media only screen and (min-width: 2200px) {
    padding: 7rem 0;
  }

  @media only screen and (max-width: 1800px) {
    padding: 5rem 24rem;
  }


  @media only screen and (max-width: 1600px) {
    padding: 5rem 14rem;
  }


  @media only screen and (max-width: 1200px) {
    padding: 5rem 7rem;
  }

  @media only screen and (max-width: 1100px) {
    padding: 5rem;
  }

  @media only screen and (max-width: 1000px) {
    padding: 5rem .5rem;
  }

  @media only screen and (max-width: 990px) {
    padding: 5rem 2rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 5rem 0rem;
  }
}


.banner{
  position: relative;
  background-image: url("../img/banner.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4) !important;
    z-index: -1;
    transition: 0.5s;
  }

  &-1{
    position: relative;
    background-image: url("../img/notre-entreprise-banner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4) !important;
      z-index: -1;
      transition: 0.5s;
    }
  }

  &-2{
    position: relative;
    background-image: url("../img/nos-services-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4) !important;
      z-index: -1;
      transition: 0.5s;
    }
  }

  &-3{
    position: relative;
    background-image: url("../img/contact-banner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4) !important;
      z-index: -1;
      transition: 0.5s;
    }
  }

  &-4{
    position: relative;
    background-image: url("../img/devis-banner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4) !important;
      z-index: -1;
      transition: 0.5s;
    }
  }

  &_content{
    padding: 14rem 0;

    &-title{
      font-family: var(--font-primary);
      font-size: 3rem;
      font-weight: 700;
      color: #fff;
      line-height: 1.9;
      text-align: center;

      @media only screen and (max-width: 768px) {
        font-size: 4rem;
      }
    }

    &-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      z-index: 1000;

      &--link {
        font-family: var(--font-primary);
        font-size: 1.8rem;
        line-height: 1.1;
        font-weight: 600;
        color: #FF5E14;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
        padding-bottom: 0;
      }

      &--icon{
        font-size: .7rem !important;
        line-height: 1.7;
        margin-bottom: .5rem;
      }

      &--active {
        color: #fff;
        transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: none;
          color: #FF5E14;
          cursor: pointer;
        }
      }
    }
  }
}

.img-small{
  width: 100%;
  max-width: 100%;
  height: 30rem !important;
}


.sell{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--color-primary);
  box-shadow: 0 3px 9px #ff7400;
  width: 100%;
  z-index: 999;
  margin-top: 2rem;

  &_content{
    padding: 1rem 0;

    &-title{
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 600;
      text-align: center
    }
  }
}

.page404{

  &_content{
    padding: 7rem 0;

    &-title{
      color: var(--color-secondary);
      font-family: "Inter", sans-serif;
      font-size: 25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }

    &-p{
      color: #292F36;
      font-family: "Roboto", sans-serif;
      font-size: 3.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.35px;
      margin: 2rem 0;
    }
  }

  &_image{

    &-img{
      width: 163.8rem;
      height: 108.8rem;
      max-width: 100%;
      border-radius: 0 0 0 50%;
      object-fit: cover;
    }
  }
}

.success-message {
  color: green;
  font-size: 1.4rem;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 1.4rem;
  text-align: center;
}

.fixphone {
  position: fixed;
  z-index: 10000 !important;
  bottom: 0;
  right: .5rem;
  font-family: "PT Sans Caption", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;
  color: #FFFFFF;

  @media (max-width: 400px) {
    bottom: 0 !important;
    right: 0 !important;
  }

  .button {
    margin-bottom: 2rem;
    margin-right: 1rem;
    padding: 1.3rem 2.5rem;
    border-radius: 1.5rem;
    color: #fff;
    background-color: var(--color-primary);
    display: inline-block;
    border: 1px solid white;
    transition: all 0.5s;

    @media (max-width: 400px) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }

    &:hover {
      text-decoration: none;
      background: #fff;
      border: 2px solid var(--color-primary);
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}

.carousel h1 {

  @media only screen and (max-width: 768px) {
    top: 10% !important;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem !important;
  }
}


.align-bullet{
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  line-height: 1.5 !important;
}



// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item {
    color: $body-color !important;

    &.active {
      color: $white !important;
    }
  }
}
